<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';

const content = `

[中文翻译件说明事项：中文翻译仅供参考，合同以英文版本为准。中文翻译件难以体现某一用语是否为已经定义的词汇并且具有特定含义（譬如，当提及交易时，如果结合英文版阅读，可以发现交易（大写的“Transaction”）指的仅仅为本主交易确认书下的交易，在中文翻译件中很难体现这一含义），因此建议结合英文原文（包括被纳入本文件的股权定义及2006年定义）一并阅读本中文翻译件。]


日期：          [● ]

致：          [ 填写对手方完整法律名称或对手方护照或身份证载明的姓名 ]

地址：          [● ]

电邮：          [● ]

收件人：     [● ]

来自：     蜂投财富管理有限公司(Beevest Wealth Management Limited)

地址：     香港銅鑼灣禮頓道77號禮頓中心10樓1013及1015室

电邮：     customer-service@beevestwm.com

收件人：     Customer Service Department 客服部

关于：     股票互换和股票篮互换主交易确认书

敬启者：

本股票互换及股票篮互换主交易确认书（本“主交易确认书”）的目的是确认将由蜂投财富管理有限公司(Beevest Wealth Management Limited)（“甲方”或“蜂投”）与[                              填入对手方完整法律名称或对手方护照或身份证载明的姓名]（“乙方”或“对手方”）不时订立的股票互换交易及/或股票篮互换交易（分别称“交易”）的若干一般条款和条件，并推动该等交易的订立和确认。双方同意每项交易均是下述ISDA主协议所指的交易。适用于每项交易的确认书（应构成ISDA主协议所指的“确认书”）应由本主交易确认书构成，并由交易补充协议所载的、适用于该交易的交易详情（补充协议可采用本协议所附附件1（股票互换的交易补充协议）或附件2（股票篮互换的交易补充协议）的格式或双方同意的其他格式）或者对前述任何一项的任何修订（分别称“交易补充协议”）作为补充。除非交易补充协议中另有规定，本主交易确认书的所有规定均适用于每份确认书。尽管有上述规定，双方确认和同意，签署本主交易确认书并不代表双方必须根据本主交易确认书的规定制备任何其他具体交易的文件。

蜂投与对手方于[          填入日期]签署了ISDA 2002年主协议（经不时修订和补充）（“主协议”）。本主交易确认书连同任何交易补充协议对主协议进行补充，构成主协议的一部分，并受限于主协议。除非下文中另有规定，主协议所载的所有规定适用于本主交易确认书。

《2002年ISDA股权衍生工具定义书》（“股权定义”）和《2006年ISDA定义书》（“2006年定义”）（均由国际掉期及衍生工具协会(“ISDA”)出版）所载的定义和规定已纳入本主交易确认书。如就任何交易而言，不同文件的条款或定义有任何不一致之处，则就该交易而言，下列文件将按照以下优先顺序适用：(i)交易补充协议；(ii)本主交易确认书；(iii)股权定义；(iv)2006年定义；及(v)主协议。

本主交易确认书所涉及的每项股票互换交易及股票篮互换交易的一般条款如下（除非相关交易补充协议另有规定），并由与该交易有关的交易补充协议补充：

一般条款：

交易日：     按交易补充协议指定的内容确定。

生效日：     按交易补充协议指定的内容确定。

终止日：     除非交易补充协议另有规定，系指最终现金结算付款日，或者最终股息付款日（两者中较晚的一日）。

股票：     如为股票互换交易，按交易补充协议指定的内容确定。

如为股票篮互换交易，按交易补充协议中的股票明细表附录指定的内容确定。

股票篮：     如为股票篮互换交易，按交易补充协议指定的内容确定。

交易所：     按交易补充协议指定的内容确定。

相关交易所：     所有交易所。

对冲方：     蜂投。

假定经纪自营商：     和对冲方或其指定的任何关联方适用于相同的证券法律，以及监管机关、交易所和自律监管机构的规则和法规的一个假定的经纪自营商。

股权金额：

股权金额付款人：     蜂投。

股票数量：     按交易补充协议指定的内容确定，根据下文关于交易部分提前终止的规定而减少。


股票篮数量：     如为股票篮互换交易，按交易补充协议指定的内容确定，会根据下文关于交易部分提前终止的规定而减少。

股权名义金额：     在任何一日：

(a)     就股票交易而言，等于股票数量乘以期初价格的积；及

(b)     就股票篮交易而言，股票篮中每只股票的价值之和（等于每只股票的期初价格与股票篮相关股票数量的积），乘以股票篮数量的积。

在上述任何一种情况下，交易补充协议另有规定的除外。

回报种类：     总回报，但交易补充协议另有规定的除外。

期初价格：     按交易补充协议指定的内容确定。


期末价格：
(a)     就股票互换交易而言，每股有效价格。该每股有效价格等于假定经纪自营商（按商业上合理的方式行事）在估值日或在最终执行期间内（如计算代理人判定假定经纪自营商为了按商业上合理的方式终止或变现全部的适用对冲头寸，而需要利用最终执行期间（而非仅在估值日）来实现终止或变现）在终止或变现适用对冲头寸时将可实现的交易量加权平均价，并经调整以反映交易成本或任何其他成本、佣金或双方可能不时另行约定的其他费用以及任何交易税费，并根据外汇规定进行换算（如适用）；及

(b)     就股票篮交易而言，股票篮金额，等于各发行人的股票的价值之和，即(i)根据上文(a)段确定的每股有效价格与(ii)股票篮内相关股票数量的积；

     在每一种情况下，均由对冲方全权酌情确定并由对冲方书面通知对手方。

     就本主交易确认书而言：

     “适用对冲头寸”系指在任何时候，对冲方判定假定经纪自营商（按商业上合理的方式行事）届时为对冲订立和履行相关交易（或交易的相关部分）下的义务有关的股权价格风险和股息风险，所将认为实属必要的对冲头寸。

     “最终执行期间”系指从估值日（含该日）到假定经纪自营商能够按商业上合理的方式终止或变现其全部适用对冲头寸的最早日期（含该日）（后一日期称为“最终执行截止日”）。为免疑问，当出现“额外终止事件”章节规定的任何情况时，“最终执行期间” 系指在出现相关情形之后，假定经纪自营商按商业上合理的方式确定需要开始终止或变现适用对冲头寸的日期（含该日）起到假定经纪自营商能够按商业上合理的方式终止或变现其全部适用对冲头寸的最早日期（含该日）（后一日期为“最终执行截止日”）。

估值时间：     就估值日或最终执行期间的每一日（视情况而定）而言，估值时间应为假定经纪自营商（善意及按商业上合理的方式行事）为确定相关期末价格而将会终止或变现适用对冲头寸的每一时间点（由计算代理人确定）。

估值日：     按交易补充协议指定的内容确定。

乙方应付的期初交换金额：

乙方期初交换金额：     按交易补充协议指定的内容确定。

乙方期初交换日：     按交易补充协议指定的内容确定。

甲方应付的期末返还金额：

甲方期末返还金额：     等于乙方期初交换金额的金额。

甲方期末返还日：     终止日，不考虑其中对“最终股息付款日”的提述。

固定金额：

固定利率付款人：     对手方。

名义本金总额：     除非交易补充协议另有规定，等于股权名义金额减去乙方期初交换金额的金额。

固定利率付款人付款日：     按交易补充协议指定的内容确定。

固定利率：     按交易补充协议指定的内容确定。

固定利率计息基准：     按交易补充协议指定的内容确定。

结算条款：

现金结算：     适用。

结算货币：     按交易补充协议指定的内容确定。

现金结算付款日：     在估值日或最终执行截止日（两者中较晚的一日）后的满一个结算周期的日期，或在估值日或最终执行截止日（两者中较晚的一日）的若干天数（该天数如交易补充协议中所指定的）货币营业日后的日期；但是，在每一种情况下，如该日期不是货币营业日，则为下一个货币营业日。

外汇规定：     就一项交易而言，如计算或确定任何股息金额或期末价格的货币不同于结算货币，则计算代理人应考虑其认为相关的所有可用信息以确定该项金额或价格按结算货币计算的价值，该等信息应包括其认定在假定经纪自营商（按商业上合理的方式行事）将该金额或价格换算为结算货币的情况下将适用的汇率。


股息（下列规定在回报种类为总回报的情况下适用）：

股息期间：     第二期间，但是，就确定最终股息期间的第二期间而言，与适用对冲头寸特定部分有关的估值日应视为假定经纪自营商将终止或变现该部分的一个或多个日期（按上文“期末价格”的规定确定）。

股息金额：     就股票、相关股息期和相关股息付款日而言，以下各项的积：(i)除息金额（减去任何税款（包括但不限于任何转让或注册印花税、资本利得税、预提税或其他适用的类似税收），费用（包括但不限于任何经纪佣金、股票交易所或清算系统的收费、征费或其他类似收费）和任何其他可能适用于发行人向假定经纪自营商支付的股息金额的交易支出），与(ii)股票数量（或就股票篮互换交易而言，相关股票数量乘以股票篮数量），根据外汇规定换算（如适用）。

股息付款日：     就股息金额而言，系指在股票发行人向股票登记持有人支付相关股息之日后第[四]个货币营业日当日或其之前的日期。

股息补偿：     如(i)发行人就发行人向股票登记持有人宣派的任何现金股息总额（“宣派股息”）不等于发行人向股票登记持有人实际支付或交付的金额（“股息错配事件”），或(ii)发行人截至相关到期日后第三个货币营业日仍未就该宣派股息作出任何支付或交付，则在上述任何一种情况下计算代理人可以（但没有义务）决定：

     (a)     应由一方作出适当调整或偿还，以反映该股息错配事件或未支付或未交付（视情况而定）的后果；

(b)     应作出偿还的日期或该调整的生效日（如适用）；及

(c)     就任何偿还金额应付的利息。

如计算代理人决定应由一方作出该等偿还或支付利息，则确定的金额应在计算代理人指定的日期支付。即使终止日已过，本条（股息补偿）的规定仍将适用并保持充分的效力和作用。

调整：

调整方法：     计算代理人调整。在确定任何潜在调整事件对股票理论价值的任何摊薄或集中影响的存在和程度以及对交易条款的任何相关调整时，计算代理人应考虑与该潜在调整事件有关的任何交易税费的影响。

特别事件：

合并事件的结果：     股票换股票：     计算代理人调整。

     股票换其他：     计算代理人调整。

     股票换组合：     计算代理人调整。

合并事件日：     应修订股权定义第12.1(b)条，将倒数第四行的“合并日”改为“合并事件日”。

     应修订股权定义第12.2(b)和12.2(e)条，将出现的每一处“合并日”改为“合并事件日”。

     就合并事件而言，“合并事件日”系指发生该合并事件的日期，该日期应视为公告日或计算代理人在相关情况下确认具有商业合理性的其他日期。

收购要约：     适用。

收购要约的后果：     股票换股票：     计算代理人调整。

     股票换其他：     计算代理人调整。

     股票换组合：     计算代理人调整。

     要约日：应修订股权定义第12.3(a)及12.3(d)条，将出现的每一处“收购要约日”改为“收购要约事件日”。

     就收购要约而言，“收购要约事件日”系指发生该收购要约的日期，该日期应视为公告日或计算代理人在相关情况下确认具有商业合理性的其他日期。

国有化、无力偿债或退市：     取消并付款，或者，就股票篮交易而言，部分取消并付款。

[信用支持规定：

本“信用支持规定”的规定仅在期初交换金额小于股权名义金额的情况下适用于交易。

信用规定：     在适用范围内，根据本主交易确认书订立的任何交易均不是双方之间日期为[●]的信用支持附件或双方可能不时订立的任何信用支持附件所指的“受管辖交易”，因此，在根据相关信用支持附件确定“风险敞口”时，应忽略根据本主交易确认书订立的交易。

收盘价：     就原定交易日而言，等于交易所就该原定交易日公布的官方每股收盘价，但是，如该原定交易日为干扰日，则计算代理人可以善意估计确定该干扰日的每股价格（而如此确定的金额应为该日收盘价）。

贷款价值比：     在任何日期，按如下公式计算的商数所得的比率（以百分比表示，最小为零）：

     ( A + B + C ) / ( A + B )

     其中：

“A”系指期初交换金额；

“B”系指名义本金总额；及

“C”系指就交易期间的某一日而言，由计算代理人确定的、一方应向另一方支付的金额（如有），该金额等于(a)股权金额、任何应计但未付的股息金额以及任何担保余额之和，减去(b)任何应计但未付的固定金额以及其他任何交易项下的或与交易相关的乙方应向甲方支付（但尚未支付）的金额之和，届时也应适用以下条件：

(i) 仅用于确定“C”的价值：

(a)     最近的上一个原定交易日为估值日;

(b)     期末价格应由计算代理人参考估值日的收盘价计算，

(ii) 甲方应支付给乙方的金额应以正数表示; 乙方应支付给甲方的金额应以负数表示; 及

(iii) 该金额应根据外汇规定进行换算（如适用）。

现金担保余额：     在任何时候，等于蜂投根据信用支持规定就相关交易已收到的总保证金追加金额减去蜂投当时已支付的总保证金返还金额。

现金担保余额不计利息。

保证金追加：     (a)     如在任何原定交易日，计算代理人确定贷款价值比等于或低于保证金追加水平（每一个该等日期称“保证金追加日”），计算代理人可向对手方交付实质上采取附件4（保证金追加通知格式）所载格式的通知（每一份该等通知称为“保证金追加通知”），要求对手方支付一笔金额（“保证金追加金额”），以便在保证金追加日如将该金额加到现金担保余额，贷款价值比（参照在保证金追加日的收盘价计算）将等于（或尽可能接近但不低于）期初贷款价值比水平。该保证金追加通知可在保证金追加日当日或之后的任何时间交付，并且，尽管有（经双方不时修订的）本协议第12条（通知）的规定，只要保证金追加通知在某日（该日期称为“保证金追加通知日”）下午11:59（香港时间）之前由蜂投发出，该保证金追加通知应在蜂投发出之日生效。

     (b)     (x)在保证金追加通知日后的营业日，对手方应不晚于该营业日中午12点（香港时间）向蜂投划转至少等于保证金追加金额的金额；并且(y)在保证金追加通知日后的营业日，对手方应不晚于该营业日中午12点（香港时间）向蜂投交付形式和内容符合蜂投要求的、证明上述金额已经向蜂投指定账户以符合双方不时商定的方式作出适当划转的证据（“保证金划转证据”）。


保证金返还：     (a)     如在任何原定交易日（该日期称为“保证金返还计算日”），计算代理人确定在该原定交易日的贷款价值比以及在紧接该保证金返还计算日之前的三个连续原定交易日中的每一日的贷款价值比皆高于保证金返还水平，对手方可向蜂投发出一份实质上采取附件5（保证金返还通知格式）规定的格式的通知（每一份该等通知称为“保证金返还通知”），要求蜂投向对手方返还等于下列金额中较低者的金额：

     (i)     如从现金担保余额中扣除该金额，则将导致贷款价值比（参照保证金返还计算日的股票担保余额计算）等于（或尽可能接近但不小于）在保证金返还计算日的期初贷款价值比水平；及

     (ii)     保证金返还计算日的现金担保余额，

（该等金额称为“保证金返还金额”）。

     (b)     蜂投将在收到保证金返还通知后第五个营业日（“保证金返还日”）[不晚于下午5点（香港时间）支付等于相关保证金返还金额的金额，但前提是：

     (i)     蜂投不晚于保证金返还计算日后一个营业日的下午4点（香港时间）收到保证金返还通知；

(ii)     没有任何阻止事件已经发生且正在持续，或者将会被该等金额返还所触发；

(iii)     保证金返还金额是不小于以下金额：[                           填入货币和金额]；及

     (iv)     计算代理人确信，在返还相关保证金后，贷款价值比将不会低于期初贷款价值比水平（使用最新收盘价），且保证金返还金额不超过现金担保余额。

期初贷款价值比水平：     按交易补充协议指定的内容确定。

保证金追加水平：     按交易补充协议指定的内容确定。

保证金返还水平：     按交易补充协议指定的内容确定。

所有权的转让：     对手方确认和陈述，对手方根据本信用支持规定向蜂投划转的保证金追加金额（如有）的所有权利、所有权和利益将不附带对手方或任何第三人的任何担保、权利主张、押记或权利负担或任何其他利益。保证金追加金额的每次转账均将构成对手方将其对该保证金追加金额的法定和实益所有权向蜂投的有效的且具有法律效力的转让。

双方同意，无意以任何一方为受益人在对手方根据这些信用支持规定向蜂投划转的任何保证金追加金额之上设立任何按揭、押记、质押、担保、权利负担或其他担保权益。

其他干扰事件：

法律变更：     适用。

     在与本主交易确认书所涉及交易有关的范围内，用以下规定替换股权定义第12.9(a)(ii)条：

     “(ii)“法律变更”系指，在交易的交易日当日或之后，(A)由于通过任何适用法律或法规（包括但不限于任何税法）或该等适用法律或法规发生任何变更，或者(B)由于有管辖权的任何法院、裁判庭或监管当局颁布任何适用法律或法规的解释或对其进行任何调整（包括税务机关采取的任何行动），计算代理人确定交易的一方持有、取得或处置与交易有关的对冲头寸在接下来的30个日历日内将会成为或很有可能会成为非法或者已经成为非法。但是，如计算代理人确定该交易方本可采取合理措施以避免该等非法情况，则本第12.9(a)(ii)条不适用。”

无力偿债申请：     适用，但是，股权定义第12.9条中“无力偿债申请”的定义应进行修订，删除在该定义末尾“但前提是，如债权人启动法律程序或提交申请且未取得发行人的同意，不应视为无力偿债申请”，并用以下规定替换：

     “；或者其已针对其启动法律程序，以寻求根据任何破产或无力偿债相关法律或者影响债权人权利的其他类似法律作出的无力偿债或破产判决或任何其他救济，或者债权人已提出将债权人清盘或清算的申请，且在任何一种情况下，该法律程序在启动或提出后的十五(15)日内未被驳回、撤销、搁置或制止。”

对冲干扰：     适用，但是：

(a)     应修订股权定义第12.9(b)(iii)条，在第三行“终止交易”之后加入“或者交易受该对冲干扰影响的部分”；及

(b)     用以下规定替换股权定义第12.9(a)(v)条：

“(v)“对冲干扰”系指，在尽商业上合理的努力后，对冲方无法(A)取得、建立、重建、替换、维持、平仓或处置任何交易或资产（包括但不限于股票贷款及其他可用于制造对股票看涨或看跌敞口的交易），根据适用于对冲方的当前情况，以商业上合理的方式对冲由于订立和履行其与交易有关的义务而产生的股权价格风险和股息风险（任何该等交易或资产称为“对冲方对冲”），或者(B)实现、收回或汇出对冲方对冲的收益。但是，如纯粹因对冲方信誉恶化而发生任何该等无法对冲的情况，则不构成对冲干扰。”

新增对冲成本：     适用，但是应用以下规定替换股权定义第12.9(a)(vi)条：

“(vi)“对冲费用增加”系指对冲方将因以下事项而显著增加（与交易日存在的情况相比）税费、开支或费用（不包括经纪佣金）（税费金额包括但不限于因任何税收责任增加、税收利益减少或其与股息有关的税务状况的其他不利影响而产生的任何税费金额）（“对冲成本”）：(A)取得、建立、重建、替换、维持、平仓或处置对冲方对冲，或(B)实现、收回或汇出对冲方对冲的收益。但是，如纯粹因对冲方信誉恶化而导致显著增加，则不构成新增对冲成本。”

确定方：     蜂投（针对所有特别事件）。

股票篮的部分终止：     尽管有股权定义第12.9(b)条的规定，如相关交易是股票篮互换交易，则与每个额外干扰事件相关的后果特此进行修订，使得终止交易的权利仅限于受相关额外干扰事件影响的股票代表的交易部分，并且，如行使任何该等部分终止权利，则(a)将仅就终止部分计算取消金额，(b)交易的其余部分仍将继续，股票篮将由不受相关额外干扰事件影响的股票组成，以及(c)计算代理人将根据需要调整任何相关条款，以尽可能地为剩余股票篮保留交易的经济条款。

取消金额：     在与本主交易确认书所涉及交易有关的范围内，用以下规定替换股权定义第12.7和12.8条：

     “第12.7条。发生若干特别事件时付款。

     就特别事件而言，如“取消并付款”适用于或被视为适用于交易（或交易的一部分），则一方应向另一方支付按第12.8条规定确定的一笔款项。付款时间应不晚于关于计算代理人确定该项金额以及付款方的通知生效之日后的第三个货币营业日。计算代理人应在确定后及时作出通知。

     第12.8条。取消金额。

     “取消金额”系指由计算代理人确定的、一方应向另一方支付的金额，该金额等于(a)股权金额、期末返还金额、任何应计但未付的股息金额以及任何担保余额之和，减去(b)任何应计但未付的固定金额、（如适用）蜂投应收的任何提前还款的融资成本、迟延履行利息、追缴服务费以及任何其他乙方应付给蜂投但未付的金额，届时也应适用以下条件：

     (i)     除以下第(ii)款另有规定外，假定经纪自营商（按商业上合理的方式行事）将会因交易（或其相关部分）的终止或取消而终止或变现任何适用对冲头寸（或其相关部分）的第一日（由计算代理人确定）应是交易（或者，视情况而定，已被终止或取消的部分）的估值日和最终股息期间的最后一日；及

     (ii)     如未全部终止或取消交易，则就确定取消金额而言，股票数量（或者，就股票篮互换交易而言，股票篮数量）将等于与被终止或取消的交易相关的股票或股票篮数量；而且交易将仅就等同于(a)紧接部分终止或取消之前的股票数量（或者，就股票篮互换交易而言，股票篮数量）减去(b)与被终止或取消的交易相关的股票数量或股票篮数量后所得的股票数量（或者，就股票篮互换交易而言，股票篮数量）继续，继续进行的该部分交易不受部分终止或取消的影响。

     就本第12.8条而言，应修订适用对冲头寸的定义，在其末尾增加以下内容：

     “（或者，就确定任何部分取消金额而言，按比例计算的那部分对冲头寸）。”

终止结算净额：     即使协议或本主交易确认书有任何相反规定，就本主交易确认书所涉及的交易而言，且在适用法律或法规许可的范围内，与该终止交易有关的终止结算净额系指：由计算代理人确定的、一方应向另一方支付的金额，该金额等于(a)股权金额、期末返还金额、任何应计但未付的股息金额以及任何担保余额之和，减去(b)任何应计但未付的固定金额、（如适用）蜂投产生的任何提前还款的融资成本、迟延履行利息、追缴服务费以及任何其他乙方应付给蜂投但未付的金额，但前提是，假定经纪自营商（按商业上合理的方式行事）将会因交易的终止而终止或变现任何适用对冲头寸（或其相关部分）的第一日（由计算代理人确定）应是交易的估值日和最终股息期间的最后一日。

     双方确认并同意，如果蜂投（或其关联方）通过与任何司法辖区的任何一方（“对冲对手方”）进行掉期、远期、期权或期货或其他衍生工具交易（“对冲交易”）作为适用对冲头寸来对冲其在本交易中的义务，且对冲对手方在对冲交易中由于任何原因（包括当地交易所或清算所违约，对持有相关证券、期权或期货合同的适用法律、规则和法规的变更）就对冲交易未能或延迟作出付款或交付，则蜂投在本交易下支付相应到期款项的义务也应相应减少和/或延迟。

不倚赖：     适用。

关于对冲活动的同意和确认：     适用。

其他确认：     适用。

选择性提前终止：

以下选择性提前终止规定将适用于非对冲方，而不[]适用于对冲方：

(i)如本规定适用于一方（“选择性提前终止选择方”），则选择性提前终止选择方可选择在估值日之前的任何原定交易日全部或部分终止交易，届时应向另一方发出口头或书面通知（“终止通知”），其中指明与其有意终止的交易相关的股票数量（或股票篮数量）（“终止股票数量”或“终止股票篮数量”）以及其建议的提前终止日。选择性提前终止日应是假定经纪自营商（按商业上合理的方式行事）将终止或变现适用对冲头寸的拟议提前终止日当日或之后的第一日（由计算代理人确定），而计算代理人应在合理可行的范围内尽快向双方通知该选择性提前终止日。

(ii)如发出终止通知，除下文第(iii)及(iv)段另有规定外，期末返还金额、股权金额、固定金额和股息金额应根据本主交易确认书的规定来确定，但应符合以下条件：选择性提前终止日应被视为估值日及最终股息期间的最后一日；并且，如终止股票数量或终止股票篮数量小于股票数量或股票篮数量（视情况而定），还应符合以下条件：在本主交易确认书中对“股票数量”或者（就股票篮互换交易而言）“股票篮数量”的提述应视为提述终止股票数量或终止股票篮数量，而“适用对冲头寸”的定义视为已作出修订，在其末尾加上“（或者，就部分选择性提前终止而言）按比例计算的那部分对冲头寸”。

(iii)如终止股票数量或终止股票篮数量小于股票数量或者（就股票篮互换交易而言）股票篮数量，则交易将仅就等同于(a)紧接选择性提前终止日之前的股票数量或股票篮数量减去(b)终止股票数量或终止股票篮数量（视情况而定）后所得的股票数量或股票篮数量继续有效。

(iv)应调整固定金额，以反映计算代理人确定的对冲方产生的任何提前还款的融资成本。

通知和账户详情：

按主交易确认书规定：

通知联系详情：

蜂投：     customer-service@beevestwm.com

对手方：     [●]

付款指示：

蜂投：     按交易补充协议指定的内容确定。

对手方：     按交易补充协议指定的内容确定。

蜂投指定办事处：     香港

对手方指定办事处：      [●]。

计算代理人：     蜂投。计算代理人负责作出每项交易项下、未明文指明负责的判定方的所有判定。

沪港/深港股票通：     除非在相关交易补充协议中另有规定，应适用下列规定：

     (a)ISDA于2014年10月14日发布的《通过沪港/深港股票通服务交易的股票的额外规定》（可通过以下链接在ISDA网站上获取：https://www.isda.org/book/additionalprovisions-for-shares-traded-throughthe-china-connect-service/）已被纳入并适用于本主交易确认书。

(b)就《通过沪港/深港股票通服务交易的股票的额外规定》而言：

a.沪港/深港股票通股票取消资格：对冲方。

b.沪港/深港股票通服务终止：对冲方。

     即使本主交易确认书有任何相反规定，如在已经或可能违反或超过适用法律规定的持股限制的情况下，香港联合交易所有限公司要求强制出售任何对冲头寸，则相关交易应被视为已发生对冲干扰事件。

其他规定：     表决权：尽管股权定义有任何规定，对手方不会因任何本主交易确认书下的交易而取得与任何股票相关、作出表决或表示同意的任何权利。在不影响前述规定的一般性的前提下，对手方无权根据本主交易确认书下的任何交易（不论直接或间接）就任何股票（包括蜂投或代表蜂投持有、作为交易对冲的任何股票）作出表决或就表决的作出发出指令，或者作出或指示作出与该等股票相关的任何同意的表示，并且，双方同意，蜂投不得接受来自对手方或其任何管理人员、董事、雇员、代理人或代表（如适用）关于该等表决或同意的任何指示或指令。

额外终止事件：

以下事件中的任何一项均是与本主交易确认书的相关交易有关的额外终止事件，对手方应是唯一受影响方，而相关交易（或交易的相关部分）将是受影响交易：

(a)发行人未能支付超过[20,000,000]美元起点金额的任何金额（包括现金担保、利息支付或融资金额），且未在[三十(30)]个日历日内作出补救。

(b)发行人处置其任何核心业务活动（不论是其整体或其重大部分），但是，仅在发行人处置其核心业务活动后[九十(90)日]当日或之后方触发提前终止。

(c)相关股票已至少在三(3)个连续原定交易日出现干扰日。

(d)[发行人控制权变更。就本项而言，“发行人控制权变更”指发行人的实际控制人在交易日：

(i)不再控制发行人，其中“控制”系指指示实体的管理和政策的权力，不论是通过拥有具有表决权的资本、合同的约定或其他方式；或

(ii)处置其对发行人直接或间接持股中的重要部分，导致其在处置后不再是发行人的最大股东。]

(e)相关股票收盘价已经连续三(3)个原定交易日较上一原定交易日收盘价下跌10%或以上。

(f)对手方未能按照本主交易确认书向蜂投交付相关保证金或相关保证金划转证据。

(g)其他蜂投按商业上合理的方式善意确定的需要终止相关交易的情形。

在不影响蜂投在法律和本协议下的其他权利以及可采取的补救措施的前提下，即使本协议的其他条款有相反的规定，如果出现上述任何情形，蜂投将有权在未事先通知对手方的情况下单方决定 (x) 终止或变现部分或全部的适用对冲头寸；并 (y) 部分或全部终止的相关交易。

在蜂投根据本“额外终止事件”的规定选择全部或部分终止交易的情况下：

(i)蜂投应（在考虑相关头寸的实际变现日期的情况下）按照商业上合理的方式来决定相关交易或交易的相关部分的提前终止日（“额外提前终止日”）以及（在部分终止的情况下）有意终止的交易相关的股票数量（或股票篮数量）（“额外提前终止股票数量”或“额外提前终止股票篮数量”），在做出决定后，蜂投会在合理可行的范围内尽快通知乙方。

(ii)除下文第(iii)及(iv)段另有规定外，期末返还金额、股权金额、固定金额和股息金额应根据本主交易确认书的规定来确定，但应符合以下条件：额外提前终止日应被视为最终股息期间的最后一日；并且，如额外提前终止股票数量或额外提前终止股票篮数量小于股票数量或股票篮数量（视情况而定），还应符合以下条件：在本主交易确认书中对“股票数量”或者（就股票篮互换交易而言）“股票篮数量”的提述应视为提述额外提前终止股票数量或额外提前终止股票篮数量，而“适用对冲头寸”的定义视为已作出修订，在其末尾加上“（或者，就部分提前终止而言）按比例计算的那部分对冲头寸”。

(iii)如额外提前终止股票数量或额外提前终止股票篮数量小于股票数量或者（就股票篮互换交易而言）股票篮数量，则交易将仅就等同于(a)紧接额外提前终止日之前的股票数量或股票篮数量减去(b) 额外提前终止股票数量或额外提前终止股票篮数量（视情况而定）后所得的股票数量或股票篮数量继续有效。

(iv)应调整固定金额，以反映计算代理人确定的对冲方产生的任何提前还款的融资成本。

迟延履行利息

乙方应为其在本协议下任何到期应付但未付的金额向蜂投支付违约利息（“迟延履行利息”）。迟延履行利息的计息期自相关款项的原定到期日开始（含该日）至相关清偿日（含该日）止。迟延履行利息在计息期内按照违约利率计算。

主协议中关于“违约利率”的定义将被以下定义所取代：

“违约利率”等于[●]，如果该利率高于适用的法律允许的利率，则违约利率应为适用的法律允许范围内的最高利率。

如果本条“迟延履行利息”条款和主协议中关于“违约利率”的任何规定相冲突，将以本条为准。

追缴服务费

乙方认可并同意，如果乙方在保证金追加通知生效后的三个营业日内，仍未按照通知要求全额支付相应的保证金追加金额，蜂投为追缴相应的保证金追加金额，会产生一定的追缴服务费用（“追缴服务费”）。除非有蜂投认可的合理原因外，乙方应补偿蜂投所产生的追缴服务费。乙方认可并同意，每个日历日追缴服务费用为股权名义金额的0.13%。

为免疑问，双方同意并认可，追缴服务费为单独于本协议约定的任何迟延履行利息或其他任何费用外的应由乙方支付的额外补偿金额。

乙方的其他陈述、保证和承诺：

除了附件3（关于沪港/深港股票通股票的陈述和保证）所载的相关陈述、保证和承诺以外，就根据本主交易确认书订立的任何交易而言，作为相关确认书构成的交易条款的一部分，对手方还在交易日、生效日以及对手方作出终止通知以及做出付款的每一日向蜂投陈述和保证：

(a)在订立交易时，其无意在终止日前的任何时间就发行人股票提出全面要约；

(b)其是“专业投资者”（定义见《证券及期货条例》及根据《证券及期货条例》制定的任何规则），并具备财务及商务事项方面的知识、经验及专长，能够评估交易的优点、风险及适当性，且能够承担交易的经济风险；

(c)其已向蜂投充分披露其订立交易的理由和目标，且该等目标在目前和过去均是符合其最佳商业利益的真实合法的业务及商业目标；

(d)其在目前和过去[均是在正常业务过程中订立交易，且其][]并非是为押注或赌博而订立交易；

(e)其已经就交易和在本主交易确认书作出的保证、陈述和承诺以及其在与交易相关的所有适用法律和法规项下的义务取得独立的法律建议；

(f)除本协议项下的交易外，在对手方全额支付根据本主交易确认书项下未完成交易应付的全部金额之前，未经蜂投事先书面同意，对手方不得 （并应确保任何股东或其各自的关联方不得） /(并应确保其关联方不得)进行与相关股票有关的股权融资；

(g)即使本协议有任何其他规定，未经蜂投事先书面同意，对手方不得在作为一家投资公司或控股公司的日常业务过程中进行交易（不包括本协议项下的交易），经营任何业务，拥有任何资产（不包括相关股票）或承担任何责任（不包括本协议项下的任何责任）及专业费用和管理费用；及

(h)其董事会（或者，如不适用，同等管理机构）已批准其订立交易及履行其在交易项下的义务。

就协议第3条和第5(a)(iv)条而言，每一项上述陈述以及（在适用范围内）附件3（关于沪港/深港股票通股票的陈述和保证）所载的每一项陈述构成一项额外陈述（定义见协议）。

其他定义和解释：

在本主交易确认书中：

就任何一日而言，“阻止事件”系指下列任一事件：

(a) 蜂投：

(i)可根据上述信用支持规定发出保证金追加通知；或

(ii)已根据上述信用支持规定发出保证金追加通知，但所需的保证金追加金额尚未根据该规定划转；及

(b)潜在违约事件或违约事件或（如适用）额外终止事件已经就对手方发生且正在持续。

“现金”系指以结算货币以及[港元和美元]计价的现金。

“股权融资”系指涉及或与股票或提述股票或发行人的工具相关的任何保证金贷款、股权衍生工具、可交换或可转换债券、股票贷款、回购或其他类似的股票相关融资、投资、对冲或货币化交易。

“交易成本”系指，就假定经纪自营商（按商业上合理的方式行事）可建立与交易相关的对冲头寸有关的任何股票而言，假定经纪自营商因取得、持有、处置、变现或实现该等股票而产生的任何经纪佣金、证券交易所或结算系统收费、征费或其他类似费用，具体由对冲方按商业上合理的方式善意确定。

“交易税费”系指，就假定经纪自营商（按商业上合理的方式行事）可建立与交易相关的对冲头寸有关的任何股票而言，该假定经纪自营商就或因取得、持有、处置、变现或实现任何该等股票而将会产生的任何转让或登记印花税、资本利得税、代扣税或类似税费，但不包括就净收益征收的任何税费或类似税费，具体由对冲方按商业上合理的方式善意确定。

“融资成本”系指，除非双方另有约定，就一项交易而言，(a)假设交易没有被提前终止，按照原定终止日计算的乙方应付的固定金额与 (b) 在提前终止的情况下，按照提前终止日计算的乙方应付的固定金额，二者之间的差额。


双方已签署本文件，自本文件第一页首次指明的日期起生效，以资信守。

Beevest Wealth Management Limited

（蜂投财富管理有限公司）

签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________


 [乙方的完整法律名称或乙方护照或身份证载明的姓名]

签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________


附件1

股票互换的交易补充协议

本交易补充协议由下列甲方与乙方在下列交易日订立。

本文件旨在确认蜂投财富管理有限公司（Beevest Wealth Management Limited）（“甲方”或“蜂投”）与[填入乙方的完整法律名称或乙方护照或身份证载明的姓名]（“乙方”或“对手方”）在下列交易日订立的股票互换交易（“交易”）的条款和条件。本交易补充协议补充双方之间日期为[填入日期]的主交易确认书（“主交易确认书”，经不时修订和补充），构成主交易确认书的一部分，受限于主交易确认书，并共同构成双方之间的协议（经不时修订和补充）提述的“确认书”。

为免疑问，如果乙方在本协议下有任何到期应付但未付的款项或有任何到期应履行但未履行的其他义务，或就乙方发生了（并仍在存续）任何违约事件或者潜在违约事件或者额外终止事件，蜂投在本协议下的任何付款或还款义务或其他义务（包括不同交易下的付款、还款或其他义务）都可以暂停履行直至相关情形不再存续。

本交易补充协议涉及的公开市场股权融资股票互换交易的条款如下：

一般条款：

交易日：     [●]

生效日：     [●]

终止日：     现金结算付款日，或者最终股息付款日（两者中较晚的一日）。

股票：     [填入股票发行人的完整法律名称]的[股票的全称、类别及/或面值以及股票的任何其他识别号或编号]

交易所：     [上海证券交易所]/[深圳证券交易所]

回报种类（股息的支付）：     回报种类为总回报，即在每个股息付款日，股权金额付款人将向股权金额收款人支付相关股息金额。

对冲方：     蜂投。

结算货币：     港元（“HKD”）

当地货币：     在香港交付的中国法定货币（“CNH”）

营业日：     位于(a)香港和(b)（针对在上海证券交易所上市的股票）上海或（针对在深圳证券交易所上市的股票）深圳的银行及外汇市场开门营业的日子。

乙方应付的期初交换金额：

乙方期初交换金额：     HKD [填入以结算货币计价的金额]

乙方期初交换日：     生效日

甲方应付的期末返还金额：

甲方期末返还金额：     等于乙方期初交换金额的金额

甲方期末返还日：     终止日，不考虑其中对“最终股息付款日”的提述。

应付股权金额：

股权金额：     根据以下公式计算得出的数额，并根据外汇规定进行换算（如适用）：

 [ ( 期末价格 – 期初价格 ) / 期初价格 ] × 股权名义金额

股权金额付款人：     蜂投。

股权金额收款人：     对手方。

股权金额的支付：     如计算代理人在估值日相关估值时间按上述公式计算得出的股权金额是一个正数，则股权金额付款人将（在股权金额付款人应付的任何其他款项之外）在现金结算付款日向股权金额收款人支付股权金额。

如计算代理人在估值日相关估值时间按上述公式计算得出的股权金额是一个负数，则股权金额收款人将（在股权金额收款人应付的任何其他款项之外）在现金结算付款日向股权金额付款人支付股权金额的绝对值。

股票数量：     [●]

期初价格：     期初价格为期初价格（不含佣金）加上佣金金额后的金额，并根据外汇规定进行换算（如适用），总计CNH [●]。

期初价格（不含佣金）：     CNH [●]

佣金金额：     期初价格（不含佣金）的 [●]%。

期末价格：     假定经纪自营商在估值日或（如适用）在最终执行期间内按照主交易确认书的规定计算的每股价格，其中主交易确认书在期末价格的规定中所提及的佣金为每股有效价格的[●]%。

股权名义金额：     在任何一日，等于当日的股票数量乘以期初价格和期初外汇汇率的积（如果没有规定期初外汇汇率，根据外汇规定进行换算（如适用））。

交易日的股权名义金额应为HKD [●]。

估值日：     [●]

估值时间：     就估值日或最终执行期间的每一日（视情况而定）而言，根据主交易确认书确定的每一时间点。

应付固定金额：

固定利率付款人：     对手方。

固定利率收款人：     蜂投。

固定金额：     就一个固定利率付款人付款日而言，根据以下公式计算：

名义本金总额×固定利率×计息期的固定利率计息基准（在适用范围内，以确定固定金额为目的，如果蜂投和对手方已对计息期的实际日数另有约定，则应适用该等由蜂投和对手方共同商定的日数），

并根据外汇规定进行换算（如适用）。

计息期：     就一个固定利率付款人付款日而言，从固定利率付款人付款日所在的日历月的第一日（包含该日）开始到该日历月的最后一日（包含该日），但前提是第一个计息期应从生效日（包含该日）开始，最后一个计息期应在终止日（不含该日）结束。

固定金额的支付：     （除固定金额付款人应付的任何其他款项之外）固定金额付款人将在相关固定利率付款人付款日上午11点（香港时间）或之前向固定金额收款人支付固定金额。

名义本金总额：     蜂投和对手方共同商定的本交易的融资最大值，即HKD [●]。

固定利率付款人付款日：     在交易期间的每个日历月的最后一个营业日，前提是第一个固定利率付款人付款日应为[●]且最后一个固定利率付款人付款日应为终止日。

固定利率：     每年[●]，但如果计算代理人以善意及按商业上合理的方式认定路透社显示屏上香港银行工会香港银行同业拆息页面（Reuters Screen HKAB HIBOR Page）（或者以计算代理人选择的在市场上具有公信力的其他来源作为替代）显示的隔夜港元利率有任何重大变动，计算代理人有权（但没有义务）调整固定利率以反映该等变动。

固定利率计息基准：     实际日数/360。

乙方应付的交易安排费用：

交易安排费用付款：     除本交易应付的其他款项外，乙方还应在乙方期初交换日向甲方支付交易安排费用。

交易安排费用：     [●]

外汇规定：

外汇规定：     如计算或确定任何股息金额或期末价格的货币不是港元，则计算代理人应考虑其认为相关的所有可用信息以确定该项金额或价格按港元计算的价值，该等信息应包括其认定在假定经纪自营商（按商业上合理的方式行事）将该金额或价格换算为港元的情况下将适用的汇率。

期初外汇汇率：     计算代理人以确定股权名义金额为目的将当地货币转换为结算货币时使用的期初外汇汇率为[●]。

 [信用支持规定：

如在任何原定交易日，计算代理人确定贷款价值比等于或低于保证金追加水平（每一个该等日期称“保证金追加日”），计算代理人可向对手方交付保证金追加通知，要求对手方支付一笔金额，该金额等同于根据主交易确认书中的信用支持规定计算得出的保证金追加金额。

对手方未能及时划转保证金追加金额和/或及时向蜂投交付保证金划转证据（时间要求在主交易确认书中列明）将导致额外终止事件的发生，并因此赋予蜂投部分或全部提前终止本交易的权利。

如果(a) 在某个原定交易日的贷款价值比以及在紧接该日之前的[三个]连续原定交易日中的每一日的贷款价值比皆高于保证金返还水平；且(b)主交易确认书中的其他相关条件已得到满足，交易对手方也可根据主交易确认书中的信用支持规定，在该原定交易日要求保证金返还金额的返还。



期初贷款价值比水平：     [填入百分比]%。

保证金追加水平：     [填入百分比]%。

保证金返还水平：     [填入百分比]%。

贷款价值比：     在任何日期，按如下公式计算的商数所得的比率（以百分比表示，最小为零）：

( A + B + C ) / ( A + B )



其中：

“A”系指期初交换金额；

“B”系指名义本金总额；及

“C”系指就交易期间的某一日而言，由计算代理人确定的、一方应向另一方支付的金额（如有），该金额等于(a)股权金额、任何应计但未付的股息金额以及任何担保余额之和，减去(b)任何应计但未付的固定金额以及其他任何交易项下的或与交易相关的乙方应向甲方支付（但尚未支付）的金额之和，届时也应适用以下条件：

(i) 仅用于确定“C”的价值：

(a)     最近的上一个原定交易日为估值日;

(b)     期末价格应由计算代理人参考估值日的收盘价计算，

(ii) 甲方应支付给乙方的金额应以正数表示; 乙方应支付给甲方的金额应以负数表示; 及

(iii) 该金额应根据外汇规定进行换算（如适用）。

现金担保余额：     在任何时候，等于蜂投根据信用支持规定就相关交易已收到的总保证金追加金额减去蜂投当时已支付的总保证金返还金额。

结算条款：

现金结算：     适用。在现金结算付款日，除根据本确认书须支付的任何其他款项（例如应计但未付的固定金额）外，相关交易方应根据本确认书的条款支付与股权金额相等的金额，且任何一方无须为本交易结算的目的交付相关股份。

现金结算付款日：     估值日或最终执行截止日（两者中较晚的一日）后[●]个货币营业日。

账户详情：

甲方收款账户：

[●]

乙方收款账户：     [●]

[其他：

有限的追索权条款：     双方确认并同意，如果蜂投（或其关联方）通过与任何司法辖区的任何一方（“对冲对手方”）进行掉期、远期、期权或期货或其他衍生工具交易（“对冲交易”）作为适用对冲头寸来对冲其在本交易中的义务，且对冲对手方在对冲交易中由于任何原因（包括当地交易所或清算所违约，对持有相关证券、期权或期货合同的适用法律、规则和法规的变更）就对冲交易未能或延迟作出付款或交付，则蜂投在本交易下支付相应到期款项的义务也应相应减少和/或延迟。


Beevest Wealth Management Limited

（蜂投财富管理有限公司）

签名： ______________________________

姓名： ______________________________

职务： ______________________________

日期： ______________________________


[乙方的完整法律名称或乙方护照或身份证载明的姓名]

签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________


附件2

股票篮互换交易补充协议

本交易补充协议由下列甲方与乙方在下列交易日订立。

本文件旨在确认蜂投财富管理有限公司（Beevest Wealth Management Limited）（“甲方”或“蜂投”）与[填入乙方的完整法律名称或乙方护照或身份证载明的姓名]（“乙方”或“对手方”）在下列交易日订立的股票篮互换交易（“交易”）的条款和条件。本交易补充协议补充双方之间日期为[填入日期]的主交易确认书（“主交易确认书”，经不时修订和补充），构成主交易确认书的一部分，受限于主交易确认书，并共同构成双方之间的协议（经不时修订和补充）提述的“确认书”。

为免疑问，如果乙方在本协议下有任何到期应付但未付的款项或有任何到期应履行但未履行的其他义务，或就乙方发生了（并仍在存续）任何违约事件或者潜在违约事件或者额外终止事件，蜂投在本协议下的任何付款或还款义务或其他义务（包括不同交易下的付款、还款或其他义务）都可以暂停履行直至相关情形不再存续。

本交易补充协议涉及的公开市场股权融资股票篮互换交易的条款如下：

一般条款：

交易日：     [●]

生效日：     [●]

终止日：     现金结算付款日，或者最终股息付款日（两者中较晚的一日）。

股票：     按股票明细表附录规定

交易所：     按股票明细表附录规定

回报种类（股息的支付）：     回报种类为总回报，即在每个股息付款日，股权金额付款人将向股权金额收款人支付相关股息金额。

对冲方：     蜂投。

结算货币：     港元（“HKD”）

当地货币：     在香港交付的中国法定货币（“CNH”）

营业日：     位于(a)香港和(b)（针对在上海证券交易所上市的股票）上海或（针对在深圳证券交易所上市的股票）深圳的银行及外汇市场开门营业的日子。

乙方应付的期初交换金额：

乙方期初交换金额：     HKD [填入以结算货币计价的金额]

乙方期初交换日：     生效日

甲方应付的期末返还金额：

甲方期末返还金额：     等于乙方期初交换金额的金额

甲方期末返还日：     终止日，不考虑其中对“最终股息付款日”的提述。

应付股权金额：

股权金额：     根据以下公式计算得出的数额，并根据外汇规定进行换算（如适用）：

 [ ( 股票篮期末价格 – 股票篮期初价格 ) / 股票篮期初价格 ] × 股权名义金额

股权金额付款人：     蜂投。

股权金额收款人：     对手方。

股权金额的支付：     如计算代理人在估值日相关估值时间按上述公式计算得出的股权金额是一个正数，则股权金额付款人将（在股权金额付款人应付的任何其他款项之外）在现金结算付款日向股权金额收款人支付股权金额。

如计算代理人在估值日相关估值时间按上述公式计算得出的股权金额是一个负数，则股权金额收款人将（在股权金额收款人应付的任何其他款项之外）在现金结算付款日向股权金额付款人支付股权金额的绝对值。

股票篮内股票数量：     按股票明细表附录规定。

股票篮数量：      [●]

期初价格：     按股票明细表附录规定。

期末价格：     假定经纪自营商在估值日或（如适用）在最终执行期间内按照主交易确认书的规定计算的股票篮金额，其中主交易确认书在期末价格的规定中所提及的佣金为每股有效价格的[●]%。

股权名义金额：     在任何一日，等于股票篮中每只股票的价值之和（等于每只股票的期初价格与股票篮相关股票数量的积），乘以股票篮数量和期初外汇汇率的积（如果没有规定期初外汇汇率，根据外汇规定进行换算（如适用））。

交易日的股权名义金额应为HKD [●]。

估值日：     [●]

估值时间：     就估值日或最终执行期间的每一日（视情况而定）而言，根据主交易确认书确定的每一时间点。

应付固定金额：

固定金额付款人：     对手方。

固定利率收款人：     蜂投。

固定金额：     就一个固定利率付款人付款日而言，根据以下公式计算：

名义本金总额 × 固定利率 × 计息期的固定利率计息基准（在适用范围内，以确定固定金额为目的，如果蜂投和对手方已对计息期的实际日数另有约定，则应适用该等由蜂投和对手方共同商定的日数），

并根据外汇规定进行换算（如适用）。

计息期：     就一个固定利率付款人付款日而言，从固定利率付款人付款日所在的日历月的第一日（包含该日）开始到该日历月的最后一日（包含该日），但前提是第一个计息期应从生效日（包含该日）开始，最后一个计息期应在终止日（不含该日）结束。

固定金额的支付：     （除固定金额付款人应付的任何其他款项之外）固定金额付款人将在相关固定利率付款人付款日上午11点（香港时间）或之前向固定金额收款人支付固定金额。

名义本金总额：     蜂投和对手方共同商定的本交易的融资最大值，即HKD [●]。

固定利率付款人付款日：     在交易期间的每个日历月的最后一个营业日，前提是第一个固定利率付款人付款日应为[●]且最后一个固定利率付款人付款日应为终止日。

固定利率：     每年[●]，但如果计算代理人以善意及按商业上合理的方式认定路透社显示屏上香港银行工会香港银行同业拆息页面（Reuters Screen HKAB HIBOR Page）（或者以计算代理人选择的在市场上具有公信力的其他来源作为替代）显示的隔夜港元利率有任何重大变动，计算代理人有权（但没有义务）调整固定利率以反映该等变动。

固定利率计息基准：     实际日数/360。

乙方应付的交易安排费用：

交易安排费用付款：     除本交易应付的其他款项外，乙方还应在乙方期初交换日向甲方支付交易安排费用。

交易安排费用：     [●]

外汇规定：

外汇规定：     如计算或确定任何股息金额或期末价格的货币不是港元，则计算代理人应考虑其认为相关的所有可用信息以确定该项金额或价格按港元计算的价值，该等信息应包括其认定在假定经纪自营商（按商业上合理的方式行事）将该金额或价格换算为港元的情况下将适用的汇率。

期初外汇汇率：     计算代理人以确定股权名义金额为目的将当地货币转换为结算货币时使用的期初外汇汇率为[●]。

[信用支持规定：

如在任何原定交易日，计算代理人确定贷款价值比等于或低于保证金追加水平（每一个该等日期称“保证金追加日”），计算代理人可向对手方交付保证金追加通知，要求对手方支付一笔金额，该金额等同于根据主交易确认书中的信用支持规定计算得出的保证金追加金额。

对手方未能及时划转保证金追加金额和/或及时向蜂投交付保证金划转证据（时间要求在主交易确认书中列明）将导致额外终止事件的发生，并因此赋予蜂投部分或全部提前终止本交易的权利。

如果(a) 在某个原定交易日的贷款价值比以及在紧接该日之前的[三个]连续原定交易日中的每一日的贷款价值比皆高于保证金返还水平；且(b)主交易确认书中的其他相关条件已得到满足，交易对手方也可根据主交易确认书中的信用支持规定，在该原定交易日要求保证金返还金额的返还。

期初贷款价值比水平：     [填入百分比]%。

保证金追加水平：     [填入百分比]%。

保证金返还水平：     [填入百分比]%。

贷款价值比：     在任何日期，按如下公式计算的商数所得的比率（以百分比表示，最小为零）：

( A + B + C ) / ( A + B )



其中：

“A”系指期初交换金额；

“B”系指名义本金总额；及

“C”系指就交易期间的某一日而言，由计算代理人确定的、一方应向另一方支付的金额（如有），该金额等于(a)股权金额、任何应计但未付的股息金额以及任何担保余额之和，减去(b)任何应计但未付的固定金额以及其他任何交易项下的或与交易相关的乙方应向甲方支付（但尚未支付）的金额之和，届时也应适用以下条件：

(i) 仅用于确定“C”的价值：

(a)     最近的上一个原定交易日为估值日;

(b)     期末价格应由计算代理人参考估值日的收盘价计算，

(ii) 甲方应支付给乙方的金额应以正数表示; 乙方应支付给甲方的金额应以负数表示; 及

(iii) 该金额应根据外汇规定进行换算（如适用）。

现金担保余额：     在任何时候，等于蜂投根据信用支持规定就相关交易已收到的总保证金追加金额减去蜂投当时已支付的总保证金返还金额。

结算条款：

现金结算：     适用。在现金结算付款日，除根据本确认书须支付的任何其他款项（例如应计但未付的固定金额）外，相关交易方应根据本确认书的条款支付与股权金额相等的金额，且任何一方无须为本交易结算的目的交付相关股份。

现金结算付款日：     估值日或最终执行截止日（两者中较晚的一日）后[●]个货币营业日。

账户详情：

甲方收款账户：

[●]

乙方收款账户：     [●]

其他：

有限的追索权条款：     双方确认并同意，如果蜂投（或其关联方）通过与任何司法辖区的任何一方（“对冲对手方”）进行掉期、远期、期权或期货或其他衍生工具交易（“对冲交易”）作为适用对冲头寸来对冲其在本交易中的义务，且对冲对手方在对冲交易中由于任何原因（包括当地交易所或清算所违约，对持有相关证券、期权或期货合同的适用法律、规则和法规的变更）就对冲交易未能或延迟作出付款或交付，则蜂投在本交易下支付相应到期款项的义务也应相应减少和/或延迟。

股票明细表附录

股票篮由下表所列发行人按下表所载与每一个发行人相关的相对比例及数量的特定股票构成。

股票：[●]

发行人：[●]

股票期初价格（这里为加入佣金后的价格，佣金为股票期初价格（不含佣金）的[●]%）：[●]

股票期初价格（不含佣金）：[●]

股票代码：[●]

股票篮内股票数量：[●]

交易所：[上海证券交易所]/[深圳证券交易所]



蜂投财富管理有限公司

（Beevest Wealth Management Limited）


签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________



 [乙方的完整法律名称或乙方护照或身份证载明的姓名]

签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________



附件3

关于沪港/深港股票通股票的陈述和保证

除非在相关交易补充协议中另有规定，对手方在交易日、生效日及对手方作出终止通知的每一日向蜂投陈述和保证：

(a)其未（如适用，通过其董事、管理人员、雇员、代理人、其任何关联方或者其关联方的董事、管理人员、雇员或代理人（分别称“相关个人”）或以其他方式）掌握与发行人有关的任何“内幕信息”（定义见《中国证券法》（不时修订））（“内幕信息”）；

(b)其遵守所有适用法律和法规项下与交易相关的所有有关报告、通知、披露（在适用于衍生工具交易的范围内）及限售期要求，包括但不限于《中国证券法》和中国主管监管机关（包括但不限于中国证监会）不时颁布的其他监管规定；

(c)其未违反《中国证券法》、相关交易所上市规则或任何其他适用法律和法规的任何规定；及

(d)非中国人士陈述：

(i)其不是(1)持有中国（不包括香港、澳门和台湾）居民身份证或政府颁发的其他同等身份证明，且不属于另一个司法管辖区永久居民或者香港、澳门或台湾永久居民的自然人，或(2)在中国注册的法人，或者，如果其是该等人士或法律实体，则其订立交易并未违反中国法律和法规，包括与外汇管控和报告有关的法律和法规（如(1)或(2)项所述的每位人士称为“国内投资者”）；

(ii)如对手方是作为信托的受托人订立交易，则信托的权益并非大部分由一名或多名国内投资者拥有，且信托的管理决定并非由一名或多名国内投资者控制。为避免疑义，只在投资管理人全权管理信托投资的情况下，就本项陈述而言，该投资管理人不得仅因其能够控制与该实体财务、投资及/或经营策略有关的决策而被视为控制该实体；

(iii)其作为本人而非任何人士或实体的代理人订立本主交易确认书（包括参与每项交易）。

只要对手方须承担协议项下的未完成交易有关的任何义务：

(a)对手方确认，蜂投及/或其任何关联方可能会被相关政府或监管机关（包括但不限于中国证监会和外汇局）或应任何法律、法规、命令或其他的合法要求，被要求披露（包括但不限于）有关交易详情或在交易中任何拥有法定或实益权益一方的身份信息，且对手方同意所有该等相关披露，并特此放弃与之相关的保密性；及

(b)对手方同意及时向蜂投和相关政府或监管机关提供相关政府或监管机关可能不时要求蜂投及/或其关联方提供的与交易有关、关于对手方或实益拥有人的身份及其他详细，包括但不限于(i)对手方所属的类别（即对冲基金、公司、个人、养老基金、信托等）；及(ii)对手方的资金来源。对手方同意，如由代表对手方和信托基金的任何第三方保管该等信息，其应确保与该第三方设立适当程序，以便应要求及时向蜂投、其指定的关联方及/或相关政府或监管机关披露该等信息，

其中：

“中国证监会”系指中国证券监督管理委员会；

“在中国注册的法人”系指在中国（不包括香港、澳门和台湾）注册成立或组建的实体；

“中国”系指中华人民共和国；

“中国证券”系指在中国（不包括香港、澳门和台湾）任何证券交易所上市的任何股票、债券、认股权证或其他证券，以人民币报价的证券投资基金或者合格境外机构投资者或人民币合格境外机构投资者根据中国（不包括香港、澳门和台湾）法律和法规可不时投资的任何其他财务工具；

“合格境外机构投资者”系指《合格境外机构投资者境内证券投资管理办法》（经不时修订和补充）界定的合格境外机构投资者；

“人民币合格境外机构投资者”系指《人民币合格境外机构投资者境内证券投资试点办法》（经不时修订和补充）界定的人民币合格境外机构投资者；

“外汇局”系指中国国家外汇管理局；及

“信托”包括信托基金或任何类似安排，其中信托资产的法定所有权由受托人或法定代表享有，但信托资产的实益权益由受益人享有；而“受托人”应作相应解释。


附件4

保证金追加通知格式

致：     [填入乙方姓名]（“乙方”）

来自：     蜂投财富管理有限公司（Beevest Wealth Management Limited）（“甲方”）

日期：     [                   ]

股票互换和股票篮互换主交易确认书（“主交易确认书”）

1.兹提述主交易确认书。本文件是保证金追加通知。除非在本保证金追加通知中被赋予不同含义，主交易确认书定义的术语应在本保证金追加通知中具有相同含义。

2.在[                       ]（“保证金追加日期”）贷款价值比低于保证金追加水平。

3.根据“信用支持规定”中的“保证金追加”章节，您必须不晚于保证金追加通知日后一营业日的[中午12点]（[香港]时间）将总额至少为[                       ]（“保证金追加金额”）的现金划转至下列账户：

账户名称：          [●]

账号：               [●]

开户行：          [●]

SWIFT代码：     [●]

4.根据“信用支持规定”中的“保证金追加”章节，您必须不晚于保证金追加通知日后第一个营业日的[中午12点]（[香港]时间）将保证金划转证据交付至下列地址：

[电子邮件地址：     [●]

传真：               [●]

联系人：          [●]

5.未能转移保证金追加金额和/或交付保证金划转证据将导致交易提前终止和适用对冲头寸变现。

6.本保证金追加通知以及因此产生或与之相关的任何非合同义务适用香港法。

蜂投财富管理有限公司（Beevest Wealth Management Limited）


签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________


附件5

保证金返还通知格式

致：          蜂投财富管理有限公司（Beevest Wealth Management Limited）（“甲方”）

来自：          [填入乙方姓名]（“乙方”）

日期：          [  ]


[日期]股票互换和股票篮互换主交易确认书日期（“主交易确认书”）

1.兹提述主交易确认书。本文件是保证金返还通知。除非在本保证金返还通知中被赋予不同含义，主交易确认书定义的术语应在本保证金返还通知中具有相同含义。

2.根据“信用支持规定”中的“保证金返还”章节，我们要求将数额为[                  ]的现金转入下列账户：

账户名称：          [●]

账号：               [●]

开户行：          [●]

SWIFT代码：     [●]

3.我们确认，并没有任何阻止事件已经发生且正在持续，或者将会被本保证金返还通知中要求的金额返还所触发。

4.本保证金返还通知以及因此产生或与之相关的任何非合同义务适用香港法。


[填入乙方姓名]

签名： ______________________________
姓名： ______________________________
职务： ______________________________
日期： ______________________________
附件6

关于其他衍生产品（例如：股票期货或期权）的有关条款


经友好协商，蜂投及对手方同意，本“主交易确认书”也可在特定情形下覆盖其他衍生产品的交易，例如股票期货或期权。该等衍生产品交易的详细条件和要求，应经过蜂投及对手方的充分讨论和一致同意。在一般情形下，下述权利、义务应得到蜂投及对手方的尊重和履行：

1. 具体交易何种衍生产品，需经蜂投及对手方事先充分讨论并达成一致

2. 在本“主交易确认书“及其附件所提及的交易安排细节也同样适用于该等衍生产品，此外：

   a)  该等衍生产品的收益权互换中涉及到的“股权金额“，是指该等衍生产品在互换合约执行期初到期末的全面收益权变动，其计算应采取市场通行的计算方法，不受本”主交易确认书“中主要针对股票或股票蓝所列出的计算方法之限制

  b)    蜂投有权在该等衍生产品的交易开始之前，为该等衍生产品设定独特的信用支持规定，并在交易开始之前通知对手方

  c)    蜂投有权根据各种情况的变化，不时变更2.b)中所制定的信用支持规定，并通知对手方

3. 针对该等衍生产品，蜂投会向对手方提供“交易补充协议”，以确认该等交易的发生及详细的交易条款，该等“交易补充协议”与本“主交易确认书”附件1的格式相仿

`.replace(/\r?\n/g, '<br />');
export default {
  name: 'baal',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content,
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        bwm_mca_chi: '是',
      });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '协议详情');
      store.commit('setStepsShow', false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.bwm_mca_chi === '是';
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
